import { Link } from 'gatsby'
import React from 'react'
import { useAuth } from '../hooks/useAuth'
import logo from '../images/pausit_sv.png'

export default function Navbar() {
  const isBrowser = () => typeof window !== "undefined"
  const { isLoggedIn } = useAuth();
  return (
    <nav>
      {isBrowser() && window.location.pathname !== "/shop/checkout" && window.location.pathname !== "/shop/checkout/order-received"
        ? <Link to={isLoggedIn ? "/user" : "/login"}><img src={logo} alt="logo" /></Link>
        : <img src={logo} alt="logo" />
      }
    </nav>
  )
}
