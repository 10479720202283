import { gql, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Layout from './Layout';
import ClipLoader from 'react-spinners/ClipLoader'
import { useAppState } from './context';
import { navigate } from 'gatsby-link';
import { useCustomerQuery } from '../hooks/queries/useCustomerQuery';

const ACTIVATE_USER = gql`
   mutation($input: ActivateUserInput!) {
    activateUser(input: $input) {
      mutationStatus
    }
  }
`
export default function ActivateUser(match) {

  const [status, setStatus] = useState('resolving')
  const [message, setMessage] = useState('Aktiverar ditt konto. Var god vänta')
  const { setIsLoggedIn } = useAppState();
  const { getCustomer, data: customerData } = useCustomerQuery();
  const [activateUser] = useMutation(ACTIVATE_USER, {
    onCompleted: (res) => {
      console.log(res);
      // setStatus('resolved')
      console.log('User successfully activated');
      console.log('Kör Login Mutation');
      // setStatus('resolving')
      setMessage('Du loggas nu in...')
      console.log(res);
      setIsLoggedIn(true);
      getCustomer();
      setTimeout(() => {
        navigate("/shop")
      }, 2000);
      
    },
    onError: (err) => {
      console.log(err.message);
      setMessage("")
      setStatus('isActivated')
    }
  })

  useEffect(() => {
    activateUser({
      variables: {
        input: {
          key: match?.params?.key
        }
      }
    })
  }, [match, activateUser])

  return (
    <Layout>
      <div className="activate-user">
        <h2>{status === 'isActivated' ? 'Användaren är redan aktiverad' : 'Aktivera konto'}</h2>
        <div className="messages">
          <div className="text-center spinner">
            {status !== 'isActivated' && <p>{message}</p>}
            {status === 'resolving' &&
              <ClipLoader size={22} color={"#ffffffbb"} />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}
