import React from 'react'
import '../css/styles.scss'
import Navbar from './Navbar'
import Helmet from 'react-helmet'
import Favicon from '../images/favicon.png'
// import { CookiesProvider } from 'react-cookie';

export default function Layout({ children }) {
  return (
    <div className="layout">
      <Helmet htmlAttributes={{
        lang: 'sv',
      }}>
        <meta charSet="utf-8" />
        <title>Pausit | Webbshop</title>
        <link rel="shortcut icon" href={Favicon}></link>
      </Helmet>
      <Navbar />
      {/* <CookiesProvider> */}
        <div className="content">
          {children}
        </div>
      {/* </CookiesProvider> */}
    </div>
  )
}
